import { useState, useEffect } from 'react';
import { getAllTempUsers, deleteTempUserById } from '../services/httpService';
import { toast } from 'react-toastify';

export const useFetchTemporaryUsers = () => {
  const [tempUsers, setTempUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchTempUsers = async () => {
          try {
              const response = await getAllTempUsers();
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
               // Mapping over the data to transform `dateExpiration` and `activationLinkSentAt`
              const formattedData = data.map(user => ({
                ...user,
                dateExpiration: new Date(user.dateExpiration).toLocaleDateString(),
                activationLinkSentAt: new Date(user.activationLinkSentAt).toLocaleDateString(),
              }));
              setTempUsers(formattedData);
          } catch (error) {
              setError(error);
          } finally {
              setLoading(false);
          }
      };

      fetchTempUsers();
  }, []);

   // Delete Temp user function
   const deleteTempUser = async (tempUserId) => {
    setLoading(true);
    try {
        const response = await deleteTempUserById(tempUserId)
        if (!response.ok) {
            throw new Error('Failed to delete the temperory user');
        }
        setTempUsers(prevTempUsers => prevTempUsers.filter(user => user.id !== tempUserId));
        toast.success('Temperory User deleted successfully!');
    } catch (error) {
        console.error('An error occurred while deleting the user', error);
        toast.error('Failed to Delete Temperory user');
    } finally {
      setLoading(false);
    }
  };

  return { tempUsers, loading, error, deleteTempUser };
};