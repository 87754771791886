const baseUrl = process.env.REACT_APP_API_BASE_URL;

//localhost:8080 :
export const http = (url, options = {}) => {
  options.mode = 'cors';
  return fetch(baseUrl + url, options)
};

//Accueil, CreationCv
export const getProfile = () => {
  const token = localStorage.getItem("token");
  return http("user/profile", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


export const fetchManagers = async () => {
  const token = localStorage.getItem("token");
  const response = await http("user/managers", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    return response.json();  
  } else {
    throw new Error("Error fetching managers");
  }
};

// Accueil 
export const authenticateUser = (body) => {
  return http("api/v1/auth/authenticate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  });
};

// Accueil et SuperAdmin
export const getUploadedFiles = () => {
  return http("clients/uploads", {
    method: "GET",
  });
};

export const deleteImage = (filename) => {
  const token = localStorage.getItem("token");
  return http(`clients/upload/${filename}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// insription
export const registerUser = (body) => {
  return http("api/v1/auth/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  });
};

export const forgotPassword = (email) => {
  return http("api/v1/auth/forgot-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email })
  });
};

// Candidats.jsx
export const getAllUsersWithCVs = () => {
  return http('user/all-with-cvs', {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const downloadUserPdf = (userId) => {
  return http(`user/download-pdf/${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

//Candidats.jsx, CreationCv.jsx, CreationCvSm;jsx
export const getAllUsers = () => {
  return http('user/all', {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getAllTempUsers = () => {
  return http('user-temp/all', {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteTempUserById = (userId) => {
  return http(`user-temp/delete/${userId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}

export const addCv = (userId, formData) => {
  const url = "cv/add";

  return http(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(formData),
  });
};

//CvDetail
export const getUserById = (userId) => {
  return http(`user/find/${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getUserWithCvsById = (userId) => {
  return http(`user/find-with-cvs/${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

//SuperAdmin
export const uploadImage = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return http("clients/upload", {
    method: 'POST',
    body: formData
  });
};


