import React from 'react';
import {
  MDBIcon
} from 'mdb-react-ui-kit';

function LoisirsPreview({ loisirs = [], isManagerRole, removeLoisir }) {
  const filteredLoisirs = loisirs.filter(loisir => loisir.trim() !== '');

  return (
    <div className="d-flex flex-wrap gap-2" style={{ maxWidth: "100%" }}>
      {filteredLoisirs.map((loisir, index) => (
        <div key={index} className="d-flex align-items-center">
          {/* Conteneur de l'icône, qu'elle soit présente ou non, pour garder l'espacement constant */}
          <div style={{ width: "20px", display: "flex", justifyContent: "center" }}>
            {isManagerRole && (
              <MDBIcon
                far
                icon="trash-alt"
                style={{ color: "#09B4BF", fontSize: "11px", cursor: "pointer", fontWeight: 'bold' }}
                onClick={() => removeLoisir(index)}
                role="button"
                size="lg"
              />
            )}
          </div>
          <span className="text-black" style={{ fontSize: "12px" }}>
            {loisir}{index < filteredLoisirs.length - 1 && ", "}
          </span>
        </div>
      ))}
    </div>
  );
}

export default LoisirsPreview;
