import React from 'react';
import { MDBContainer } from "mdb-react-ui-kit";
import Header from './Header'; 
import MainContent from './MainContent';
import Footer from './Footer';

const CVPreviewComponent = ({
  isAnonymous,
  userData,
  cvData,
  showExp,
  languageSkills,
  setLanguageSkills,
  translations,
  groupedCompetences,
  competenceDurations,
  experiences,
  formatDate,
  calculateDifference,
  formations,
  loisirs,
  experienceYears,
  experienceMonths,
  moveAction,
  removeCompetence,
  removeExperience,
  removeFormation,
  removeLoisir,
  isManagerRole,
  isCandidateRole,
  isClientRole,
  currentUser,
}) => (
  <MDBContainer fluid className="card cv-preview-pdf">
    <Header
      isAnonymous={isAnonymous}
      userData={userData}
      cvData={cvData}
    />
    <MainContent
      showExp={showExp}
      cvData={cvData}
      languageSkills={languageSkills}
      setLanguageSkills={setLanguageSkills}
      translations={translations}
      groupedCompetences={groupedCompetences}
      competenceDurations={competenceDurations}
      experiences={experiences}
      isAnonymous={isAnonymous}
      formatDate={formatDate}
      calculateDifference={calculateDifference}
      formations={formations}
      loisirs={loisirs}
      experienceYears={experienceYears}
      experienceMonths={experienceMonths}
      moveAction={moveAction}
      removeCompetence={removeCompetence}
      removeExperience={removeExperience}
      removeFormation={removeFormation}
      onRemoveLoisir={removeLoisir}
      isManagerRole={isManagerRole}
    />
    <Footer
      isManagerRole={isManagerRole}
      isCandidateRole={isCandidateRole}
      isClientRole={isClientRole}
      translations={translations}
      currentUser={currentUser}
      userData={userData}
    />
  </MDBContainer>
);

export default CVPreviewComponent;
