import React from 'react';
import {
  MDBIcon
} from 'mdb-react-ui-kit';

function FormationsPreview({ formations = [], isAnonymous, translations, formatDate, removeFormation, isManagerRole }) {
  return (
    <>
      {formations.map((formation, index) => (
        <React.Fragment key={index}>
          {formation && formation.ecole && (
            <>
              {isManagerRole && (
                <MDBIcon
                  far
                  icon="trash-alt"
                  style={{ color: "#09B4BF", fontSize: "11px", marginLeft: "-30px", fontWeight: 'bold' }}
                  onClick={() => removeFormation(index)}
                  role="button"
                  size="lg"
                />
              )}
            </>
          )}
          <div className="d-flex no-page-break" style={{ marginTop: isManagerRole ? '-20px' : '0' }}>
            <div style={{ width: '15%', marginRight: '10px' }}>
              <p className="fw-bold mb-0 text-black" style={{ fontSize: "12px" }}>
                {isAnonymous && formation.ecole ? translations.labels.school : formation.ecole}
              </p>
              {(formation.dateDebut || formation.dateFin || formation.isCurrentFormation) && (
                <p className="mb-0 text-black" style={{ fontSize: "11px" }}>
                  {formation.dateDebut ? formatDate(formation.dateDebut) : ""}
                  {formation.dateDebut && (formation.dateFin || formation.isCurrentFormation)
                    ? " - "
                    : ""}
                  {formation.isCurrentFormation ? translations.labels.ongoing : (formation.dateFin ? formatDate(formation.dateFin) : "")}
                </p>
              )}
            </div>
            <div className="w-75" style={{ fontSize: "12px", textAlign: "justify" }}>
              <p className="mb-0" style={{ color: "#09B4BF" }}>{translations.educationLevels[formation.niveau]}</p>
              <p className="text-black">{formation.intitule}</p>
            </div>
          </div>
          {index < formations.length - 1 && (
            (formation.ecole || formation.intitule) &&
            (formations[index + 1].ecole || formations[index + 1].intitule) && (
              <hr style={{ color: "#09B4BF" }} />
            )
          )}
        </React.Fragment >
      ))
      }
    </>
  );
}

export default FormationsPreview;
