import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from 'mdb-react-ui-kit';

const LanguagePreview = ({ languageSkills = [], translations, setLanguageSkills, isManagerRole }) => {
    // Carte de correspondance entre les noms de langues et les clés de traduction
    const languageMap = {
        "Français": "french",
        "Anglais": "english",
        "Espagnol": "spanish",
        "Luxembourgeois": "luxembourgish",
        "Chinois": "chinese",
        "Arabe": "arabic",
        "Portugais": "portuguese",
        "Russe": "russian",
        "Allemand": "german",
        "Langue maternelle": "motherTongue",
    };

    const translate = (key) => {
        // Vérifier si la clé est dans l'objet translations
        if (translations[key]) {
            return translations[key];
        }
        // Accèder à l'objet labels
        if (translations.labels && translations.labels[key]) {
            return translations.labels[key];
        }
        // Obtenir la clé à partir de la carte de langue
        const translationKey = languageMap[key] || key.toLowerCase().replace(/\s+/g, '_');
        return translations[translationKey] || translations.labels[translationKey] || key;
    };

    const removeLanguageSkill = (index) => {
        const updatedSkills = languageSkills.filter((_, i) => i !== index);
        setLanguageSkills(updatedSkills);
    };

    return (
        <div className="preview" style={{ marginTop: "-15px", overflowX: 'auto' }}>
            <MDBTable style={{ fontSize: "11px" }}>
                <MDBTableHead>
                    <tr className="text-center text-white" style={{ backgroundColor: "#09B4BF" }}>
                        {isManagerRole && <th></th>}
                        <th></th>
                        <th colSpan="2" className="fw-bold p-1">{translations.labels.understand}</th>
                        <th colSpan="2" className="fw-bold p-1">{translations.labels.express}</th>
                    </tr>
                    <tr className="text-black">
                        {isManagerRole && <th></th>}
                        <th></th>
                        <th className="text-center p-1">{translations.labels.speak}</th>
                        <th className="text-center p-1">{translations.labels.write}</th>
                        <th className="text-center p-1">{translations.labels.speak}</th>
                        <th className="text-center p-1">{translations.labels.write}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody style={{ verticalAlign: 'middle' }}>
                    {languageSkills
                        .filter(skill => skill.name)
                        .map((skill, index) => (
                            <tr key={index} className='text-black'>
                                {isManagerRole && (
                                    <th className="p-0">
                                        <MDBIcon
                                            fas
                                            icon="trash-alt"
                                            style={{ color: "#09B4BF", fontSize: "11px", width: "2px" }}
                                            onClick={() => removeLanguageSkill(index)}
                                            role="button"
                                            size="lg"
                                        />
                                    </th>
                                )}
                                <th colSpan="1" className="fw-bold p-1">
                                    <p className="p-0 m-0">{translate(skill.name)}</p>
                                </th>
                                <td className="text-center p-1">
                                    <p className="p-0 m-0">{translate(skill.oralComprehension)}</p>
                                </td>
                                <td className="text-center p-1">
                                    <p className="p-0 m-0">{translate(skill.writtenComprehension)}</p>
                                </td>
                                <td className="text-center p-1">
                                    <p className="p-0 m-0">{translate(skill.oralExpression)}</p>
                                </td>
                                <td className="text-center p-1">
                                    <p className="p-0 m-0">{translate(skill.writtenExpression)}</p>
                                </td>
                            </tr>
                        ))}
                </MDBTableBody>
            </MDBTable>
        </div >
    );
};

export default LanguagePreview;
