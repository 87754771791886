import React, { useState, useEffect } from "react";
import LanguagePreview from './LanguagePreview';
import CompetencesPreview from './CompetencesPreview';
import ExperiencesPreview from './ExperiencesPreview';
import FormationsPreview from './FormationsPreview';
import LoisirsPreview from './LoisirsPreview';

function MainContent({
    showExp,
    cvData,
    languageSkills,
    setLanguageSkills,
    translations,
    groupedCompetences,
    competenceDurations,
    experiences,
    isAnonymous,
    formatDate,
    calculateDifference,
    formations,
    loisirs,
    experienceYears,
    experienceMonths,
    removeCompetence,
    removeExperience,
    removeFormation,
    removeLoisir,
    isManagerRole
}) {
   
    return (
        <main className="px-5 content-block">
            <div className="card-body">
                {showExp && (
                    <p className="text-end" style={{ fontSize: "11px" }}>
                        {translations.workExperience.replace("{experienceYears}", experienceYears).replace("{experienceMonths}", experienceMonths)}
                    </p>
                )}
                <p
                    className="card-subtitle text-black"
                    id="job-title-preview"
                    style={{ fontSize: "12px", whiteSpace: "pre-line", textAlign: "justify" }}
                >
                    {cvData?.presentation}
                </p>
                {/* Languages */}
                <div className="subtitre-cv-container d-flex align-items-center gap-3">
                    <p
                        className="fw-bold subtitre-cv pt-3"
                        id="skills-preview"
                        style={{ fontSize: "12px" }}
                    >
                        {translations?.languages}
                    </p>
                    <div className="line"></div>
                </div>
                <LanguagePreview
                    languageSkills={languageSkills}
                    setLanguageSkills={setLanguageSkills}
                    translations={translations}
                    isManagerRole={isManagerRole}
                />

                {/* Competences */}
                <div className="subtitre-cv-container d-flex align-items-center gap-3" style={{ marginTop: "-15px" }}>
                    <p
                        className="fw-bold subtitre-cv pt-3"
                        id="skills-preview"
                        style={{ fontSize: "12px" }}
                    >
                        {translations?.businessSkills}
                    </p>
                    <div className="line"></div>
                </div>
                <CompetencesPreview
                    groupedCompetences={groupedCompetences}
                    competenceDurations={competenceDurations}
                    translations={translations}
                    isManagerRole={isManagerRole}
                    removeCompetence={removeCompetence}
                />

                {/* Experiences */}
                <div className="subtitre-cv-container d-flex align-items-center gap-3 avoid-page-break-before">
                    <p
                        className="fw-bold subtitre-cv pt-3"
                        id="skills-preview"
                        style={{ fontSize: "12px" }}
                    >
                        {translations?.experiences}
                    </p>
                    <div className="line"></div>
                </div>
                <ExperiencesPreview
                    experiences={experiences}
                    isAnonymous={isAnonymous}
                    translations={translations}
                    formatDate={formatDate}
                    calculateDifference={calculateDifference}
                    removeExperience={removeExperience}
                    isManagerRole={isManagerRole}
                />

                {/* Formations */}
                <div className="subtitre-cv-container d-flex align-items-center gap-3" style={{ pageBreakInside: 'avoid' }}>
                    <p
                        className="fw-bold subtitre-cv pt-3"
                        id="skills-preview"
                        style={{ fontSize: "12px" }}
                    >
                        {translations?.formations}
                    </p>
                    <div className="line"></div>
                </div>
                <FormationsPreview
                    formations={formations}
                    isAnonymous={isAnonymous}
                    translations={translations}
                    formatDate={formatDate}
                    removeFormation={removeFormation}
                    isManagerRole={isManagerRole}
                />

                {/* Loisirs */}
                <div className="subtitre-cv-container d-flex align-items-center gap-3 no-page-break">
                    <p
                        className="fw-bold subtitre-cv pt-3"
                        id="skills-preview"
                        style={{ fontSize: "12px" }}
                    >
                        {translations?.hobbies}
                    </p>
                    <div className="line"></div>
                </div>
                <LoisirsPreview loisirs={loisirs} isManagerRole={isManagerRole} onRemoveLoisir={removeLoisir} />
            </div>
        </main>
    );
}

export default MainContent;
